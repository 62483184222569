import React, { useEffect, useRef, useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm, FormProvider } from 'react-hook-form'
import axios from 'axios'
import { isIOS } from 'react-device-detect'

import { INUI_PROPERTIES } from '../../constants/config.js'
import Form from './Form'
import Tables from './Tables'
import Spinner from '../../components/Feedback/Spinner'
import {
  TitleStyle,
  LayoutStyle,
  SelectStyle,
  SubmitStyle,
  SubmitContainerStyle,
  SelectContainerStyle,
  ErrorMessageStyle,
  InputContainerStyle,
  InputSelect
} from './InuiContact.style.js'

const { REACT_APP_APPLI_API_URL } = process.env

const selectContactTypeOptions = [
  {
    value: 1,
    label: '駐輪場'
  },
  {
    value: 2,
    label: '駐車場'
  },
  {
    value: 3,
    label: 'バイク置き場'
  },
  {
    value: 4,
    label: 'トランクルーム'
  },
  {
    value: 5,
    label: 'プレミアム駐輪場'
  }
]

export const Options = ({ options }) => {
  return options.map((option, i) => {
    return <option value={option.value} key={i}>{option.label}</option>
  })
}

export default function InuiContact () {
  const { t } = useTranslation()
  const methods = useForm({
    defaultValues: {
      contact_type: 1
    }
  })
  const i18n = useRef(t) // Prevent useffect rerenders
  const [loading, setLoading] = useState(false)
  const [property, setProperty] = useState(null)
  const [token, setToken] = useState(window.token)

  const getProperty = useCallback(
    async () => {
      if (token) {
        try {
          const headers = {
            Authorization: token
          }
          const res = await axios.get(`${REACT_APP_APPLI_API_URL}/inui_property`, {
            headers
          })
          if (res && res.data) {
            setProperty(res?.data?.property_type)
          }
        } catch (error) {
          console.warn(error)
        }
      }
    },
    [token]
  )

  // useEffect(() => {
  //   setTimeout(() => {
  //     window.token = ''
  //     // window.token = ''
  //   }, 2000)
  // }, [])

  useEffect(() => {
    let check
    if (!isIOS && !token) {
      check = setInterval(() => {
        if (window.token) setToken(window.token)
      }, 100)
    }
    return () => {
      clearInterval(check)
    }
  }, [token])

  useEffect(() => {
    getProperty()
  }, [getProperty])

  useEffect(() => {
    if (token) {
      window.alert(i18n.current('inui:contact.alert'))
    }
  }, [i18n, token])

  const _handleSubmit = async (data) => {
    if (token) {
      const headers = {
        Authorization: token
      }
      const base = window?.webkit?.messageHandlers?.iosListener || window?.androidListener || window
      setLoading(true)
      try {
        await axios.post(`${REACT_APP_APPLI_API_URL}/services/contact`, data, {
          headers
        })

        const response = JSON.stringify({ success: true, message: '送信が完了しました。' })

        base.postMessage(response)
      } catch (error) {
        console.warn(error)
        const err = JSON.stringify({ success: false, message: error?.response?.data?.message || '送信に失敗しました' })
        base.postMessage(err)
      }
      setLoading(false)
    }
  }

  const _renderOptions = () => {
    let options = selectContactTypeOptions
    if (property === INUI_PROPERTIES.TSUKISHIMA) {
      options = selectContactTypeOptions.filter(option => option.value !== 2 && option.value !== 5)
    }
    if (property === INUI_PROPERTIES.PLAZA_KACHIDOKI) {
      options = selectContactTypeOptions.filter(option => option.value !== 5)
    }
    return <Options options={options}/>
  }

  if (!token || !property) return null

  return (
    <LayoutStyle>
      <TitleStyle>
        {t('inui:contact.title')}
      </TitleStyle>
      <FormProvider {...methods} >
        <form
          onSubmit={methods.handleSubmit(_handleSubmit)}
          noValidate
        >
          <InputContainerStyle>
            <InputSelect
              type='text'
              name='name'
              placeholder="氏名*"
              required
              ref={methods.register({ required: true })}
            />
            {methods.errors.name && <ErrorMessageStyle >この項目は必須項目です。</ErrorMessageStyle>}
          </InputContainerStyle>
          <InputContainerStyle>
            <InputSelect
              type='text'
              name='phone'
              placeholder="連絡先（ハイフンあり）*"
              required
              ref={methods.register({ required: true })}
            />
            {methods.errors.phone && <ErrorMessageStyle >この項目は必須項目です。</ErrorMessageStyle>}
          </InputContainerStyle>
          <SelectContainerStyle>
            <SelectStyle name='contact_type' ref={methods.register({ valueAsNumber: true, required: true })} required>
              <option disabled value=''>項目を選択してください。*</option>
              { _renderOptions() }
            </SelectStyle>
            {methods.errors.contact_type && <ErrorMessageStyle>項目を選択してください。</ErrorMessageStyle>}
          </SelectContainerStyle>
          <Form property={property} />
          <SubmitContainerStyle>
            <SubmitStyle type="submit" disabled={loading}>
              <span>{t('submit')}</span>
              { loading && <Spinner color="white"/> }
            </SubmitStyle>
          </SubmitContainerStyle>
        </form>
      </FormProvider>
      <Tables property={property} />
    </LayoutStyle>
  )
}
